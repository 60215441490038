.border-red {
	border: 3px solid #1890ff !important;
}

.bg-blue {
	background-color: #1890ff !important;
}

.gm-style-iw h4 {
	font-size: 13px;
}

.map-div {
	max-width: 98%;
}

.panic-text-highlight {
	color: red;
}

.table_active_page {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	border: 1px solid #dee2e6;

	z-index: 3;
	color: #fff;
	background-color: #007bff;
	border-color: #007bff;
}

.toggle-btn__input:checked~.toggle-btn__input-label::after {
	background: #003a6c;
}

.err-red-text {
	color: red;
}

.white_text {
	color: #fff;
}

.black_text {
	color: #000;
}

.text_right {
	text-align: right;
}

.text_light {
	color: #646777;
}

.text_light_b {
	color: #646777;
	font-weight: bold;
}

.fs_14 {
	font-size: 14px;
}

.width_200px {
	width: 200px;
}

.width_150px {
	width: 150px;
}

.width_50px {
	width: 50px;
}

#select-admin_select {
	font-size: 14px;
}

div#menu-admin_select li {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 14px;
}

#select-gender {
	font-size: 14px;
}

div#menu-gender li {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 14px;
}

#select-status {
	font-size: 14px;
}

div#menu-status li {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 14px;
}

[data-title]:hover:after {
	opacity: 1;
	transition: all 0.1s ease 0.5s;
	visibility: visible;
}

[data-title]:after {
	content: attr(data-title);
	position: absolute;
	bottom: 2em;
	left: -40%;
	padding: 4px 4px 4px 5px;
	color: #fff;
	/* white-space: nowrap; */
	white-space: pre;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	/* -moz-box-shadow: 0px 0px 4px #666;
	-webkit-box-shadow: 0px 0px 4px #666;
	box-shadow: 0px 0px 4px #666; */
	background-color: #3ea3fc;
	opacity: 0;
	z-index: 99999;
	visibility: hidden;
	font-size: 100%;
	text-align: left;
}

[data-title] {
	position: relative;
}

.ml-15 {
	margin-left: 15px;
}

.csv_div button {
	background-color: #00569F;
	border-color: #00569F;
	border-radius: 5px;
	padding: 7px 10px;
	margin-bottom: 10px;
	transition: all 0.4s;
	font-size: 14px;
	position: relative;
	overflow: hidden;
	z-index: 0;
	color: #fff;
}

.view_qrcode_div h5 {
	margin-bottom: 15px;
}

